import { createAction, props } from '@ngrx/store';

import { ListCitiesResult, ListRegionsResult } from '../api';
import { TranslatedCountry } from '../shared/i18n';

export const getCountries = createAction('[Cities] Get Countries');
export const getCountriesSuccess = createAction(
  '[Cities] Get Countries Success',
  props<{ payload: TranslatedCountry[] }>(),
);
export const getCountriesFailed = createAction('[Cities] Get Countries Failed', props<{ error: string }>());

export const getRegions = createAction('[Cities] Get Regions');
export const getRegionsSuccess = createAction('[Cities] Get Regions Success', props<{ payload: ListRegionsResult }>());
export const getRegionsFailed = createAction('[Cities] Get Regions Failed', props<{ error: string }>());

export const getCities = createAction('[Cities] Get Cities');
export const getCitiesSuccess = createAction('[Cities] Get Cities Success', props<{ payload: ListCitiesResult }>());
export const getCitiesFailed = createAction('[Cities] Get Cities Failed', props<{ error: string }>());
