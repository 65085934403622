import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { CityDto, RegionDto } from '../api';
import { TranslatedCountry } from '../shared/i18n';
import {
  initialStateResult,
  ResultState,
  selectIsStateLoading,
  selectResultOrEmptyArray,
  stateError,
  stateLoading,
  stateSuccess,
} from '../shared/ngrx';
import {
  getCities,
  getCitiesFailed,
  getCitiesSuccess,
  getCountries,
  getCountriesFailed,
  getCountriesSuccess,
  getRegions,
  getRegionsFailed,
  getRegionsSuccess,
} from './cities.actions';

interface State {
  countries: ResultState<TranslatedCountry[]>;
  regions: ResultState<RegionDto[]>;
  cities: ResultState<CityDto[]>;
}

const initialState: State = {
  countries: initialStateResult,
  regions: initialStateResult,
  cities: initialStateResult,
};

export const citiesFeature = createFeature({
  name: 'Cities',
  reducer: createReducer(
    initialState,
    on(getCountries, (state): State => ({ ...state, countries: stateLoading(state.countries.result) })),
    on(getCountriesSuccess, (state, { payload }): State => ({ ...state, countries: stateSuccess(payload) })),
    on(
      getCountriesFailed,
      (state, { error }): State => ({
        ...state,
        countries: stateError(error, state.countries.result),
      }),
    ),
    on(getRegions, (state): State => ({ ...state, regions: stateLoading(state.regions.result) })),
    on(getRegionsSuccess, (state, { payload }): State => ({ ...state, regions: stateSuccess(payload.items) })),
    on(
      getRegionsFailed,
      (state, { error }): State => ({
        ...state,
        regions: stateError(error, state.regions.result),
      }),
    ),
    on(getCities, (state): State => ({ ...state, cities: stateLoading(state.cities.result) })),
    on(getCitiesSuccess, (state, { payload }): State => ({ ...state, cities: stateSuccess(payload.items) })),
    on(
      getCitiesFailed,
      (state, { error }): State => ({
        ...state,
        cities: stateError(error, state.cities.result),
      }),
    ),
  ),
});

export const selectCountries = createSelector(citiesFeature.selectCountries, selectResultOrEmptyArray);
export const selectCountriesLoading = createSelector(citiesFeature.selectCountries, selectIsStateLoading);
export const selectRegions = createSelector(citiesFeature.selectRegions, selectResultOrEmptyArray);
export const selectRegionsLoading = createSelector(citiesFeature.selectRegions, selectIsStateLoading);
export const selectCities = createSelector(citiesFeature.selectCities, selectResultOrEmptyArray);
export const selectCitiesLoading = createSelector(citiesFeature.selectCities, selectIsStateLoading);
